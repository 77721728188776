<template>
	<div>
		<div
			class="min-w-screen bg-gray-800 bg-opacity-75 flex flex-col gap-9 px-5 py-10"
		>
			<h1 class="text-4xl font-bold text-center">Register Runner</h1>
			<div class="flex justify-center">
				<div
					v-if="errorstatus"
					class="w-full max-w-xs bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
					role="alert"
				>
					<span class="block sm:inline">{{ errorstatus }}</span>
					<span class="absolute top-0 bottom-0 right-0 px-4 py-3">
						<svg
							@click="errorstatus = null"
							class="fill-current h-6 w-6 text-red-500"
							role="button"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
						>
							<title>Close</title>
							<path
								d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
							/>
						</svg>
					</span>
				</div>
			</div>
			<div class="flex justify-center">
				<div v-if="!bib" class="w-full max-w-xs">
					<div class="mb-4">
						<input
							v-model="fullname"
							class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							type="text"
							placeholder="Full Name"
							autofocus
						/>
					</div>
					<div class="mb-6">
						<input
							v-model="nid"
							class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							type="text"
							placeholder="NID Card#"
						/>
					</div>
					<div class="mb-6">
						<input
							v-model="contact"
							class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							type="number"
							placeholder="Contact# (Maldives Numbers only)"
						/>
					</div>
					<div class="flex justify-center">
						<button
							@click="register"
							class="bg-red-800 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="button"
						>
							Register
						</button>
					</div>
				</div>
				<div v-else class="w-full max-w-xs">
					<h1 class="text-lg mb-1">Registration Successful</h1>
					<div class="w-full text-gray-100 mb-1">
						Name: {{ fullname }}
					</div>
					<div class="w-full text-gray-100 mb-1">
						ID Card: {{ nid }}
					</div>
					<div class="w-full text-gray-100 mb-1">
						Sequence no: {{ bibS }}
					</div>
					<div class="w-full text-gray-100 mb-1">
						Contact Number: {{ contact }}
					</div>
					<div class="mb-1">
						<button
							@click="
								bib = null;
								nid = null;
								fullname = null;
								errorstatus = null;
								contact = null;
							"
							class="bg-red-800 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="button"
						>
							New
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				fullname: null,
				nid: null,
				bib: null,
				bibS: null,
				contact: null,
				errorstatus: null,
			};
		},
		methods: {
			register() {
				if (this.fullname && this.nid && this.contact) {
					if (this.contact.length !== 7) {
						this.errorstatus = "Invalid phone number";
					} else if (
						!(this.contact[0] == 7 || this.contact[0] == 9)
					) {
						this.errorstatus = "Invalid phone number";
					} else {
						this.errorstatus = null;
						const self = this;
						this.nid = this.nid.toUpperCase();
						this.$http
							.post(process.env.VUE_APP_API_ROOT + "/runner", {
								name: this.fullname,
								nid: this.nid,
								contact: this.contact,
							})
							.then(res => {
								if (res.status == 200) {
									self.bib = res.data.bib;
									if (self.bib < 10)
										self.bibS = "000" + self.bib;
									else if (self.bib < 100)
										self.bibS = "00" + self.bib;
									else if (self.bib < 1000)
										self.bibS = "0" + self.bib;
									else self.bibS = self.bib;
								}
							})
							.catch(e => {
								this.errorstatus = e.response.data.msg;
							});
					}
				} else this.errorstatus = "Missing Fields";
			},
		},
	};
</script>
